import React from 'react';
import { SlashSquare } from 'react-bootstrap-icons';
import PropTypes from 'prop-types';
import NetworkEnum from '../../shared/enums/networkEnum';
import { ReactComponent as FacebookIcon } from '../../images/logos/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../images/logos/instagram.svg';
import { ReactComponent as GoogleAdsIcon } from '../../images/logos/googleAds.svg';
import styles from './NetworkButtons.module.css';

const networkIcons = {
  [NetworkEnum.FACEBOOK]: FacebookIcon,
  [NetworkEnum.INSTAGRAM]: InstagramIcon,
  [NetworkEnum.GOOGLE]: GoogleAdsIcon,
  [NetworkEnum.OTHER]: SlashSquare,
};

const NetworkButtons = ({
  selectedNetwork,
  onChange,
  onlyMeta = false,
  multiSelect = false,
  error,
}) => {
  const selectedNetworks =
    typeof selectedNetwork === 'string'
      ? selectedNetwork.split(',').filter(Boolean)
      : [];

  const handleNetworkClick = (network) => {
    if (multiSelect) {
      const newSelection = selectedNetworks.includes(network)
        ? selectedNetworks.filter((n) => n !== network)
        : [...new Set([...selectedNetworks, network])];
      onChange(newSelection.length > 0 ? newSelection.join(',') : null);
    } else {
      onChange(network);
    }
  };

  return (
    <div className={styles.root}>
      <label className={styles.label} htmlFor="network">
        Platform{multiSelect ? 's' : ''}
      </label>
      <div className={styles.inner}>
        {Object.values(NetworkEnum)
          .filter((network) =>
            onlyMeta ? network !== NetworkEnum.GOOGLE : true,
          )
          .filter((network) => network !== NetworkEnum.OTHER)
          .map((network) => {
            const NetworkIcon = networkIcons[network];
            const isSelected = selectedNetworks.includes(network);
            return (
              <button
                key={network}
                type="button"
                className={`${styles.networkButton} ${
                  isSelected ? styles.active : ''
                }`}
                onClick={() => handleNetworkClick(network)}
              >
                <NetworkIcon
                  width={20}
                  height={20}
                  className={styles.networkIcon}
                />
                <span className={styles.networkName}>{network}</span>
              </button>
            );
          })}
      </div>

      {Boolean(error) && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
};

NetworkButtons.propTypes = {
  selectedNetwork: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(NetworkEnum)),
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOf(Object.values(NetworkEnum))),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onlyMeta: PropTypes.bool,
  multiSelect: PropTypes.bool,
  error: PropTypes.string,
};

export default NetworkButtons;
