import React, { useEffect, useState } from 'react';
import { Trash3, BorderOuter } from 'react-bootstrap-icons';
import ColorField from '../Fields/Color';
import optionLabels from '../../Consts/OptionLabels.json';
import Button from '../../../../../Components/FormComponents/Button';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../../shared/enums/buttonEnum';
import { useMixer } from '../../MixerContext';
import TagsField from '../Fields/Tags';
import styles from './Root.module.css';

const RootOptions = () => {
  const [showBorderOptions, setShowBorderOptions] = useState(false);

  const { handleOptionChange, rootOptions, handlePreviewOption } = useMixer();

  useEffect(() => {
    const hasBorderData =
      (rootOptions.borderStyle?.[0] && rootOptions.borderStyle[0] !== 'none') ||
      rootOptions.borderColor?.[0] ||
      rootOptions.borderThickness?.[0] ||
      rootOptions.innerPadding?.[0];

    if (hasBorderData) {
      setShowBorderOptions(true);
    }
  }, []);

  const toggleBorderOptions = () => {
    setShowBorderOptions((prev) => {
      const newState = !prev;
      if (!newState) {
        handleOptionChange('borderStyle', []);
        handleOptionChange('borderColor', []);
        handleOptionChange('borderThickness', []);
        handleOptionChange('innerPadding', []);
        handlePreviewOption({ name: 'borderStyle', forceRemove: true });
        handlePreviewOption({ name: 'borderColor', forceRemove: true });
        handlePreviewOption({ name: 'borderThickness', forceRemove: true });
        handlePreviewOption({ name: 'innerPadding', forceRemove: true });
      }
      return newState;
    });
  };

  return (
    <div className={styles.root}>
      <ColorField
        name={'backgroundColor'}
        label={optionLabels['backgroundColor']}
      />
      <Button
        leftIcon={
          showBorderOptions ? (
            <Trash3 className={styles.remove} />
          ) : (
            <BorderOuter />
          )
        }
        variant={ButtonVariantEnum.TEXT}
        size={ButtonSizeEnum.S}
        onClick={toggleBorderOptions}
        className={styles.borderToggle}
      >
        {showBorderOptions ? 'Remove border' : 'Add border'}
      </Button>
      {showBorderOptions && (
        <>
          <TagsField name="borderStyle" label={optionLabels['borderStyle']} />
          <ColorField
            name={'borderColor'}
            label={optionLabels['borderColor']}
          />
          <TagsField
            name="borderThickness"
            label={optionLabels['borderThickness']}
          />
          <TagsField name="innerPadding" label={optionLabels['innerPadding']} />
        </>
      )}
    </div>
  );
};

export default RootOptions;
