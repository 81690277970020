const defaultTextItemOptions = {
  content: '',
  font: 'Arial',
  size: '20px',
  tag: 'span',
  color: '#000',
  class: '',
  position: 'left',
  style: '',
  lineHeight: '1.5',
};

export default defaultTextItemOptions;
