import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '../styles/GlobalContainerStyle';
import { useFetch } from '../../hooks/useFetch';
import TopBar from '../../ComponentsV2/TopBar/TopBar';
import { GalleryList } from './GalleryList/GalleryList';
import { GallerySidebar } from './CallerySidebar/GallerySidebar';

import {
  AllBannersGroup,
  GalleryContextProvider,
  UngroupedBannersGroup,
  useGalleryContext,
} from './Gallery.context';
import { generateBannerGroupStartElement } from './utils/generateBannerGroupsStartElement';

const GalleryLayout = ({ match }) => {
  const [bannerSetId] = useState(match.params.bannerSetId);

  const {
    filters: {
      setNetworkResolutions,
      setBannerGroups: setFilterBannerGroups,
      setSelectedResolution,
    },
    setBannerGroupsMap,
    setBannerGroups,
    setBannersList,
    setBannerSetId,
    setBannerSet,
    refetchBannerGroupsSymbol,
    setCurrentCampaign,
  } = useGalleryContext();

  const { fetchDataFn: fetchBannerGroups, data: bannerGroupsData } = useFetch(
    `/getBannerGroups/${bannerSetId}`,
    { lazy: true },
  );

  const { fetchDataFn: fetchBanners, data: bannersData } = useFetch(
    `/getBanners/${bannerSetId}`,
    { lazy: true },
  );

  const bannerSet = useMemo(
    () => bannersData?.banners?.[0]?.bannerSet,
    [bannersData],
  );

  const { fetchDataFn: fetchCampaign, data: currentCampaign } = useFetch(
    `/getCampaign/${bannerSet?.campaign}`,
    { lazy: true },
  );

  useEffect(() => {
    if (currentCampaign) {
      setCurrentCampaign(currentCampaign);
    }
  }, [currentCampaign]);

  useEffect(() => {
    if (refetchBannerGroupsSymbol) {
      void fetchBannerGroups();
    }
  }, [refetchBannerGroupsSymbol]);

  useEffect(() => {
    setBannerSetId(bannerSetId);
  }, [bannerSetId]);

  useEffect(() => {
    void fetchBanners({
      queryParams: {
        page: 1,
        limit: 100,
        populate: true,
        additional: 'gallery,elements',
      },
    });
    void fetchBannerGroups();
  }, []);

  useEffect(() => {
    if (bannersData?.banners?.length) {
      void fetchCampaign();

      setBannerSet(bannersData?.banners[0]?.bannerSet);
      const networkResolutions =
        bannerSet?.resolutions?.map((res) => `${res.width}x${res.height}`) ||
        [];
      setNetworkResolutions(networkResolutions);
      if (bannerSet?.resolutions.length === 1) {
        setSelectedResolution(networkResolutions[0]);
      }
    }

    setBannersList(
      bannersData?.banners?.filter((banner) => Boolean(banner.createdImgs)) ||
        [],
    );
  }, [bannersData, bannerSet]);

  useEffect(() => {
    const { bannerGroupsMap, customBannerGroups } =
      bannerGroupsData?.bannerGroups?.reduce(
        (acc, group) => {
          const { bannerGroupsMap, customBannerGroups } = acc;

          group.banners.forEach((bannerId) => {
            if (bannerGroupsMap[bannerId]) {
              bannerGroupsMap[bannerId].push(group);
              return;
            }

            bannerGroupsMap[bannerId] = [group];
          });

          customBannerGroups.push({
            value: group._id,
            label: group.name,
            color: group.color,
            startReactElement: generateBannerGroupStartElement(group),
          });

          return acc;
        },
        {
          bannerGroupsMap: {},
          customBannerGroups: [],
        },
      ) || {
        bannerGroupsMap: {},
        customBannerGroups: [],
      };

    setBannerGroupsMap(bannerGroupsMap);

    setBannerGroups(bannerGroupsData?.bannerGroups || []);
    setFilterBannerGroups([
      AllBannersGroup,
      UngroupedBannersGroup,
      ...customBannerGroups,
    ]);
  }, [bannerGroupsData, bannersData]);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TopBar
            title={bannerSet?.name}
            network={bannerSet?.platform}
            breadCrumbs={[
              {
                label: currentCampaign?.client?.name,
                link: `/campaign/${currentCampaign?._id}`,
              },
              {
                label: currentCampaign?.group?.name,
                link: `/groups/${currentCampaign?.group?._id}`,
              },
            ]}
          />
        </Grid>

        <Grid item xs={8}>
          <GalleryList />
        </Grid>

        <Grid item xs={4}>
          <GallerySidebar />
        </Grid>
      </Grid>
    </Container>
  );
};

export const GalleryPage = (props) => {
  return (
    <GalleryContextProvider>
      <GalleryLayout {...props} />
    </GalleryContextProvider>
  );
};
